/*

Theme: Bizoo – website Description
Version: 1.0.0
Product Page: 
License: https://themes.getbootstrap.com/licenses/
Author: Webpixels
Author URI: https://webpixels.io

---

Copyright 2020 Webpixels

*/

// Bootstrap functions
@import "../../../node_modules/bootstrap/scss/functions";

// Custom variables
@import "custom/variables";

// Bizoo functions
@import "core/functions";

@import "core/variables";

// Custom fonts
@import "custom/fonts";

// Bootstrap core
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

// Bizoo mixins
@import "core/mixins";

// Bootstrap components
@import "core/bootstrap";

// Bizoo utilities
@import "core/utilities";

// Bizoo components
@import "core/components";

// Bizoo libs
@import "core/libs";

// Custom styles (User's file)
@import "custom/styles";
