.search-dropdown {
    .search-dropdown-menu {
        width: 120%;
        position: absolute;
        top: 100%;
        padding: 1.5rem;
        margin-top: 1rem;
        background: white;
        border-radius: $border-radius-lg;
        opacity: 0;
        pointer-events: none;
        border: 1px solid $border-color;
        @include transition(opacity .3s);
        @include box-shadow($box-shadow);

        .heading {
            color: $gray-700;
        }

        .list-link {
            span {
                font-weight: $font-weight-bold;
                color: $gray-800;
            }

            &:hover {
                color: theme-color("primary");

                span {
                    color: theme-color("primary");
                }
            }
        }
    }

    &.show {
        .search-dropdown-menu {
            opacity: 1;
            pointer-events: auto;
        }
    }
}
