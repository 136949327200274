//
// Search hero
//

.search-hero {
    .search-nav {
        display: inline-flex;
        padding: .75rem;
        background-color: section-color("secondary");
        @include border-top-radius($card-border-radius);

        .nav {
            .nav-link {
                font-size: $font-size-base;

                &.active {
                    font-weight: bold;
                }
            }
        }
    }

    .search-form {
        padding: 1rem .75rem;
        background-color: section-color("secondary");
        @include border-top-right-radius($card-border-radius);
        @include border-bottom-radius($card-border-radius);

        .form-control,
        .custom-select,
         {

        }
    }
}
